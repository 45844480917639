import Cookies from 'js-cookie'
import { useState } from 'react'
import NegotiationService from '../Services/negotiationService'
import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { useForm } from 'react-hook-form'

import { useHistory } from 'react-router-dom'
import { validationMessages } from '../Mocks'

export function useCreateNegotiation() {
	const { t } = useTranslation()
	const history = useHistory()
	const { watch } = useForm()

	const watchFields = watch()

	const [isLoading, setIsLoading] = useState()
	const partnerId = Cookies.get('partnerId')
	const negotiationService = new NegotiationService()
	const [errorMessage, setErrorMessage] = useState()

	const [isInvalidDocument, setIsInvalidDocument] = useState(false)
	const [negotiatorInfos, setNegotiatorInfos] = useState(null)

	const [hasActiveNegotiation, setHasActiveNegotiation] = useState(false)

	const [partnerBranches, setPartnerBranches] = useState([])
	const [productGroups, setProductGroups] = useState([])
	const [productInfos, setProductInfos] = useState([])
	const [groupData, setGroupData] = useState([])

	const [openModalConfirmNegotiation, setOpenModalConfirmNegotiation] = useState(false)
	const [openModalCancelNegotiation, setOpenModalCancelNegotiation] = useState(false)
	const [openModalCreateCampain, setOpenModalCreateCampain] = useState(false)
	const [negotiationCampains, setNegotiationCampains] = useState(null)

	const [selectedDivisions, setSelectedDivisions] = useState(null)
	const [programRules, setProgramRules] = useState(null)

	const [startedNegociation, setStartednegociation] = useState(false)
	const [customDisableFields, setCustomDisableFields] = useState({
		groups: true,
		selectGroup: true,
		productSKU: true,
		productName: true,
		quantity: false,
		benefit: true,
		unlimitedItems: false,
		benefitGroup: true,
		percentage: true,
		value: true,
		date: true,
		hour: true,
		sellerName: true,
		description: true,
		startDate: true,
		endDate: true,
		campain: true,
		hasContract: true,
	})

	const [onClearSearch, setOnClearSearch] = useState(new Date())

	const [notificationMessage, setNotificationMessage] = useState({
		open: false,
		message: 'Negociação criada com sucesso!',
		type: 'success',
	})

	const benefitOptions = [
		{
			key: 0,
			label: 'Geração de pontos',
			value: 'Points',
			// value: 1,
		},
		// {
		// 	key: 2,
		// 	label: 'Resgate de pontos',
		// 	value: 2,
		// },
		{
			key: 2,
			label: 'Cashback',
			value: 'Cashback',
			// value: 3,
		},
	]

	const benefitGroupOtpions = [
		{ key: 1, label: 'Percentual', value: 1 },
		{
			key: 2,
			label: 'Fixo',
			value: 2,
		},
	]

	const defaultBeneftitOption = [{ key: 1, label: 'Percentual', value: 1 }]

	function changeNotificationMessage(key, value) {
		setNotificationMessage(prev => {
			return {
				...prev,
				[key]: value,
			}
		})
	}

	function changeCustomDisableFields(key, value) {
		setCustomDisableFields(prev => {
			return {
				...prev,
				[key]: value,
			}
		})
	}

	function resetDisabledFields() {
		setIsInvalidDocument(false)
		setCustomDisableFields({
			groups: true,
			selectGroup: true,
			productSKU: true,
			productName: true,
			quantity: false,
			benefit: true,
			benefitGroup: true,
			percentage: true,
			value: true,
			date: true,
			hour: true,
			sellerName: true,
			description: true,
			startDate: false,
			endDate: true,
			campain: true,
			hasContract: true,
			unlimitedItems: true,
			partialConsumption: true,
			transferValidAfter: true,
		})
	}

	function formatSelectOption(data) {
		let result = []

		data.forEach(item => {
			result.push({
				key: item?.key,
				value: item?.value,
				label: t(`CREATE_NEGOTIATION.${item?.value}`),
			})
		})
		return result
	}

	function formatSelectDefaultOption(data) {
		let result = []

		data.forEach(item => {
			result.push({
				key: item?.key,
				value: item?.value,
				label: item?.value,
			})
		})

		return result
	}

	function formatCustomSelectOption(key, newCustomKey, data) {
		let result = []

		data.forEach(item => {
			result.push({
				key: item?.key,
				value: item?.value ?? item?.name,
				label: item?.value || item?.name || item?.key,
				[newCustomKey]: item?.[key].value ?? item?.[key],
				...item,
			})
		})

		return result
	}

	async function validateDocument(document) {
		if (document) {
			setIsLoading(true)
			const response = await negotiationService.validateDocument(document)
			if (response?.errors.length > 0) {
				setIsInvalidDocument(true)
				setNegotiatorInfos(null)
				setErrorMessage(response?.errors[0].message)
				setIsLoading(false)
			}
			if (response?.data === null) {
				setIsInvalidDocument(true)
				setNegotiatorInfos(null)
				setErrorMessage('user - NOT_FOUND')
				setIsLoading(false)
			} else if (!response?.errors.length && response?.data !== null) {
				setNegotiatorInfos(response?.data)

				setTimeout(() => {
					setIsLoading(false)
				}, 2000)
			}
		}
	}

	async function formatCampainList(data) {
		const result = []
		data.forEach(item => {
			result.push({
				key: item?.id,
				value: item?.id,
				label: item?.description,
			})
		})

		return result
	}

	async function getProgramRules() {
		const response = await negotiationService.getProgramRules()
		if (response) {
			setProgramRules(response)
		}
	}

	async function validateSelectedGroupItem({ grouperId, typeOfGrouper, typeOfBenefit }) {
		const data = {
			partnerId,
			grouperId: grouperId ?? watchFields?.productSKU?.key,
			consumerId: negotiatorInfos?.id,
			typeOfGrouper,
			typeOfBenefit,
			divisions: getDivisionsId(selectedDivisions),
		}

		if (data) {
			const response = await negotiationService.validateSelectedGroup(data)
			if (response?.success === true) {
				setHasActiveNegotiation(false)
				changeCustomDisableFields('benefitGroup', false)
				changeCustomDisableFields('value', false)
			} else {
				setHasActiveNegotiation(true)
				setErrorMessage(response?.errors[0].message)
				changeCustomDisableFields('value', true)
				changeCustomDisableFields('benefitGroup', true)
			}
		}
	}

	const getPartnerBranches = useCallback(async () => {
		const response = await negotiationService.getBusinessDivisions()
		if (response) {
			const result = formatCustomSelectOption('segmentationType', 'type', response)

			setPartnerBranches(result)
		}
	}, [partnerId])

	async function getGroupData(groupType) {
		const response = await negotiationService.getGroupData(partnerId, groupType)
		if (response) {
			const result = formatSelectDefaultOption(response)
			setGroupData(result)
		}
	}

	async function getProductGroups() {
		const response = await negotiationService.getGroupers()
		if (response) {
			const result = formatSelectOption(response)
			setProductGroups(result)
		}
	}

	const getProducts = useCallback(async productFilter => {
		const response = await negotiationService.getProductInfo(productFilter)

		if (response?.data?.results?.length < 1) {
			changeNotificationMessage('message', 'Produto não encontrado!')
			changeNotificationMessage('type', 'error')
			changeNotificationMessage('open', true)
			changeCustomDisableFields('benefit', true)
			changeCustomDisableFields('productName', false)
			changeCustomDisableFields('benefitGroup', true)
		} else {
			setProductInfos(response.data?.results)
		}
	}, [])

	function openAndCloseModalConfirmNegotiation() {
		setOpenModalConfirmNegotiation(prev => !prev)
	}

	function openAndCloseModalCancelNegotiation() {
		setOpenModalCancelNegotiation(prev => !prev)
	}

	function openAndCloseModalCreateCampain() {
		setOpenModalCreateCampain(prev => !prev)
	}

	function clearMaskToCurrency(currency) {
		if (currency) {
			let cleanedValue = currency.replace(/\./g, '')

			cleanedValue = cleanedValue.replace(',', '.')

			cleanedValue = cleanedValue.replace(/[%R$]/g, '').trim()

			return parseFloat(cleanedValue)
		}
		return null
	}

	function getDivisionsId(divisions) {
		let result = []
		divisions.forEach(item => {
			result.push(item?.id ?? item?.value)
		})

		return result
	}

	async function createNegotiation(data) {
		let typeBenefitEmum = null

		if (data) {
			const benefitType = data?.benefit?.value
			const benefitGroupLabel = data?.benefitGroup?.label

			if (benefitType === 'Cashback') {
				typeBenefitEmum = benefitGroupLabel === 'Fixo' ? 4 : 3
			} else if (benefitType === 'Points') {
				typeBenefitEmum = benefitGroupLabel === 'Percentual' ? 3 : 2
			}

			const formatedData = {
				partnerId,
				consumerId: negotiatorInfos?.id,
				divisions: getDivisionsId(selectedDivisions),
				typeOfGrouper: Number(data?.groups?.key),
				grouperId: data?.selectGroup?.key ?? data?.productSKU?.key,
				typeOfBenefit: data?.benefit?.key,
				benefitConfig: typeBenefitEmum,
				limited: (data?.quantity && true) || !data?.unlimitedItems || false,
				allowedQuantity: data?.quantity ?? null,
				value: clearMaskToCurrency(data?.value),
				sellersName: data?.sellerName ?? null,
				description: data?.description ?? null,
				validFrom: data?.startDate,
				validUntil: data?.endDate,
				hasContract: data?.hasContract,
				contractNumber: data?.contractNumber ?? null,
				displayBlockedValue: data?.displayBlockedValue,
				allowPartialAvailabilityOfValue: data?.partialConsumption ?? false,
				allowTransfer: data?.allowTransfer,
				transferValidAfter: data?.transferValidAfter,
				allowPartialTransfer: data?.allowPartialTransfer ?? false,
				negotiationCampaignId: data?.campain?.value,
				limitedInvoice: (data?.nfLimit && true) || !data?.unlimitedNF || false,
				allowedInvoiceQuantity: data?.nfLimit ?? null,
			}

			try {
				const response = await negotiationService.createNegotiation(formatedData)
				if (response?.errors[0]?.message) {
					changeNotificationMessage('message', validationMessages[(response?.errors[0]?.message)] ?? 'Ocorreu um erro ao criar esta negociação!')
					changeNotificationMessage('type', 'error')
					changeNotificationMessage('open', true)
					openAndCloseModalConfirmNegotiation()
				} else {
					openAndCloseModalConfirmNegotiation()
					changeNotificationMessage('type', 'success')
					changeNotificationMessage('message', 'Negociação criada com sucesso!')
					changeNotificationMessage('open', true)

					setTimeout(() => {
						history.push('/negotiation')
					}, 1000)
				}
			} catch (error) {
				changeNotificationMessage('message', 'Ocorreu um erro ao criar esta negociação!')
				changeNotificationMessage('type', 'error')
				changeNotificationMessage('open', true)
				openAndCloseModalConfirmNegotiation()
			}
		}
	}

	async function getNegotiationsCampains() {
		const response = await negotiationService.getNegotiationCampains()
		const campains = await formatCampainList(response?.results)

		if (campains) {
			setNegotiationCampains(campains)
		}
	}

	async function createNegotiationsCampains(campain) {
		const data = {
			description: campain,
			partnerId: partnerId,
		}
		const response = await negotiationService.createNegotiationCampains(data)
		if (response?.success) {
			getNegotiationsCampains()
			changeNotificationMessage('message', 'Negociação criada com sucesso!')
			changeNotificationMessage('open', true)
			openAndCloseModalCreateCampain()
		} else {
			changeNotificationMessage('message', validationMessages[(response?.errors[0].message)])
			changeNotificationMessage('type', 'error')
			changeNotificationMessage('open', true)
			openAndCloseModalCreateCampain()
		}
	}

	function handleCloseStack() {
		changeNotificationMessage('open', false)
	}

	function validateFielsNotEmpty() {
		if (!startedNegociation || !watchFields?.sellerName || !watchFields?.description) {
			return true
		} else {
			return false
		}
	}

	const getProductsDebounce = useDebouncedCallback(value => {
		getProducts(value)
	}, 700)

	return {
		getPartnerBranches,
		partnerBranches,
		setPartnerBranches,
		getProductGroups,
		productGroups,
		setProductGroups,
		getProducts,
		productInfos,
		setProductInfos,
		validateDocument,
		isInvalidDocument,
		setIsInvalidDocument,
		negotiatorInfos,
		getGroupData,
		groupData,
		setGroupData,
		resetDisabledFields,
		startedNegociation,
		setStartednegociation,
		customDisableFields,
		setCustomDisableFields,
		changeCustomDisableFields,
		openModalConfirmNegotiation,
		setOpenModalConfirmNegotiation,
		openModalCancelNegotiation,
		setOpenModalCancelNegotiation,
		openAndCloseModalConfirmNegotiation,
		openAndCloseModalCancelNegotiation,
		validateSelectedGroupItem,
		createNegotiation,
		notificationMessage,
		setNotificationMessage,
		changeNotificationMessage,
		handleCloseStack,
		validateFielsNotEmpty,
		benefitOptions,
		benefitGroupOtpions,
		defaultBeneftitOption,
		hasActiveNegotiation,
		setHasActiveNegotiation,
		formatCustomSelectOption,
		onClearSearch,
		setOnClearSearch,
		getNegotiationsCampains,
		negotiationCampains,
		openAndCloseModalCreateCampain,
		openModalCreateCampain,
		createNegotiationsCampains,
		errorMessage,
		setErrorMessage,
		isLoading,
		setIsLoading,
		selectedDivisions,
		setSelectedDivisions,
		getProductsDebounce,
		getProgramRules,
		programRules,
	}
}
